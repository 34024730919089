<template>
  <div class="text-left">
    <BaseHeader :title="'Writer Preferences'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class="card mt-2 border-0 bg-white shadow">
        <base-select-site
          class="p-2"
          @siteSelected="getWriterPreferences"
        ></base-select-site>
        <div class="card-body text-right d-flex py-1" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="my-auto ml-2"
            size="small"
            @click.stop="crudAction"
            >New</v-btn
          >
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="WriterPreferences"
          :search="$store.state.search"
        >
          <template v-slot:[`item.default`]="{ item }">
            <v-chip v-if="item.default !== '0'" small color="primary"
              >Default</v-chip
            >
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="item.status ? 'primary' : 'error'" small outlined>{{
              item.status ? "Active" : "Inactive"
            }}</v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <!-- <router-link
                class="white--text v-btn v-btn--contained v-btn--fab v-btn--round theme--light v-size--x-small success"
                :to="{ name: 'ViewSystemClient', params: { id: item.id || '' }}"
              >
                <v-icon small class="white--text">mdi-eye</v-icon>
              </router-link> -->
            <div class="d-flex flex-col">
              <v-btn
                elevation="0"
                class="white--text mr-1"
                @click="
                  editMode(item);
                  edit = true;
                  $store.state.dialog = true;
                "
                fab
                x-small
              >
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                elevation="0"
                class="white--text"
                @click="deleteMode(item)"
                fab
                x-small
              >
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <!-- /datatable -->
      </div>

      <!-- delete dialog -->
      <v-dialog
        v-model="delete_dialog"
        class="text-left text-start"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="deleting">
          <v-card-title class="text-h5"> Delete Preference </v-card-title>
          <v-card-text>
            <div class="text-left">
              <p>Are you sure you want to delete?</p>

              <v-card-actions>
                <v-btn color="error darken-1" text @click="deletePreference">
                  Yes, Delete
                </v-btn>
                <v-btn
                  color=""
                  text
                  @click="
                    delete_dialog = false;
                    activePreference = {};
                  "
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- delete dialog -->

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="WriterPreferencesForm.busy">
          <v-card-title class="text-h5">
            {{
              edit
                ? `Update ${WriterPreferencesForm.level || ""}`
                : "New Writer Preference"
            }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="WriterPreferencesForm"
              @submit.prevent="createWriterLevel"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Title"
                      name="title"
                      type="text"
                      v-model="WriterPreferencesForm.title"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="WriterPreferencesForm"
                            class="v-messages theme--light error--text"
                            field="title"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Description"
                      name="description"
                      type="text"
                      v-model="WriterPreferencesForm.desc"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="WriterPreferencesForm"
                            class="v-messages theme--light error--text"
                            field="desc"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      outlined
                      label="Amount"
                      name="cost"
                      placeholder="Percentage to add to the total order cost"
                      min="0"
                      v-model="WriterPreferencesForm.cost"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="WriterPreferencesForm"
                            class="v-messages theme--light error--text"
                            field="cost"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="WriterPreferencesForm.busy"
                    type="submit"
                    color="primary"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Alerts from "../../../mixins/alerts";
import http from "../../modules/http.index";
export default {
  name: "WriterPreferences",
  computed: {
    ...mapState("writerpreferences", [
      "WriterPreferencesForm",
      "loading",
      "WriterPreferences",
      "headers",
    ]),
    // ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
      delete_dialog: false,
      activePreference: {},
      deleting: false,
    };
  },

  methods: {
    ...mapActions("writerpreferences", ["getWriterPreferences"]),
    // ...mapActions("clients", ["getClients"]),
    createWriterLevel() {
      this.WriterPreferencesForm.description = this.WriterPreferencesForm.desc;
      this.WriterPreferencesForm.site_id = this.$store.state.site_id;
      this.edit
        ? this.WriterPreferencesForm.put(
            `${this.$baseUrl}/Setups/WriterPreferences/${this.WriterPreferencesForm.id}`
          )
            .then((res) => {
              this.$store.state.dialog = false;
              this.successNoti(res || `Preference updated successifully`);

              this.getWriterPreferences();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.errorNoti(err || "Error while updating Preference");
            })
        : this.WriterPreferencesForm.post(
            `${this.$baseUrl}/Setups/WriterPreferences/`
          )
            .then((res) => {
              this.$store.state.dialog = false;
              this.successNoti(res || `Preference created successifully.`);
              this.getWriterPreferences();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.errorNoti(err || "Error while creating Preference");
            });
    },

    editMode(style) {
      this.edit = true;
      this.WriterPreferencesForm.reset();
      this.$store.state.dialog = true;
      Object.assign(this.WriterPreferencesForm, { ...style });
      this.WriterPreferencesForm.cost = this.WriterPreferencesForm.cost
        .replace("+", "")
        .replace("%", "");
      this.WriterPreferencesForm.default =
        this.WriterPreferencesForm.default !== "0" ? true : false;
      this.WriterPreferencesForm.active =
        this.WriterPreferencesForm.active !== "0" ? true : false;
    },

    deleteMode(preference) {
      this.activePreference = preference;
      this.delete_dialog = true;
    },

    deletePreference() {
      this.deleting = true;
      http
        .delete(`Setups/WriterPreferences/${this.activePreference.id}`)
        .then((res) => {
          this.delete_dialog = false;
          this.successNoti(res || `Preference deleted successifully.`);
          this.getWriterPreferences();
        })
        .catch((err) => {
          this.errorNoti(err || `Error while deleting Preference`);
        })
        .finally(() => (this.deleting = false));
    },

    crudAction() {
      if (this.$store.state.site_id) {
        this.edit = false;
        this.WriterPreferencesForm.reset();
        this.$store.state.dialog = true;
      } else {
        this.errorNoti("Please select a site to proceed");
      }
    },
  },
  async mounted() {
    if (this.$store.state.site_id) {
      await this.getWriterPreferences();
    }
  },
  mixins: [Alerts],
};
</script>
